<template>
  <div class="account-container">
   收款账户
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang='scss'>
.account-container {
}
</style>